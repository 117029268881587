// Translated
// Migrated to script setup

<template>
  <Alert
    v-if="success"
    class="mt-4"
    type="success"
    data-i18n="newsletterSuccess"
  >
    {{ t('newsletterSuccess') }}
  </Alert>
  <form
    v-else
    class="flex flex-col w-full"
    @submit.prevent="registerEmail"
  >
    <Alert
      v-if="error"
      class="mt-4"
      type="error"
    >
      {{ error }}
    </Alert>
    <div class="flex flex-col">
      <div
        v-if="useAirports"
        class="w-full form-group"
      >
        <b-skeleton
          v-if="loading"
          height="50px"
        />
        <template v-else>
          <label
            class="mr-4"
            for="airport"
            data-i18n="newsletterPopup.airport"
          >{{ t('newsletterPopup.airport') }}</label>
          <select
            id="airport"
            v-model="airport"
            class="form-control"
          >
            <option
              v-for="ap in newsletterAirports"
              :key="ap.id"
              :value="ap.id"
            >
              {{ ap.name }}
            </option>
          </select>
        </template>
      </div>
      <div class="w-full form-group">
        <label
          for="subscribe-email"
          data-i18n="newsletterPopup.email"
        >{{ t('newsletterPopup.email') }}</label>
        <div class="flex flex-col md:flex-row">
          <b-skeleton
            v-if="loading"
            width="75%"
            height="50px"
          />
          <template v-else>
            <input
              id="subscribe-email"
              v-model="email"
              class="form-control"
              type="email"
              autocomplete="email"
            />
          </template>
          <b-skeleton
            v-if="loading"
            class="ml-4"
            width="20%"
            height="50px"
          />
          <template v-else>
            <button
              class="btn btn-lg btn-bold mt-4 md:mt-0 md:ml-3 newsletter-form-register-btn"
              type="submit"
              :class="$isSol ? 'btn-orange' : 'btn-blue'"
              data-i18n="newsletterSubscribeButton"
            >
              {{ t('newsletterSubscribeButton') }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import Alert from '@layers/web/components/alerts/Alert'

import localStorageKeys from '@layers/web/constants/local-storage-keys.ts'

const localeStore = useLocaleStore()
const rootStore = useRootStore()
const { t } = useI18n()
const { trackNewsletterSignup } = useTracking()

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false,
  },

  campaign: {
    type: String,
    default: undefined,
  },
})

const email = ref('')
const airport = ref(undefined)
const loading = ref(false)
const error = ref(false)
const success = ref(false)

const { newsletterAirports } = storeToRefs(rootStore)
const { locale } = storeToRefs(localeStore)

// const vertical = computed(() => {
//   return !horizontal
// }) not used

const useAirports = computed(() => {
  return isSol() && (newsletterAirports.value || []).length > 0
})

onMounted(() => {
  if (isSol()) {
    expand()
  }
})

const expand = (async () => {
  loading.value = true

  if (!newsletterAirports.value?.length) {
    await rootStore.fetchNewsletterLists()
  }

  airport.value = newsletterAirports.value?.[0]?.id

  loading.value = false
})

const registerEmail = (async () => {
  error.value = false

  if (!email.value) {
    error.value = t('formFieldRequiredError')
    return
  }

  // Gtag event
  trackNewsletterSignup(airport.value)

  try {
    const { data: result, error } = await apiFetch(`/${locale.value}/newsletter-register`, {
      method: 'POST',
      body: {
        email: email.value,
        airpiort: airport.value, // yes, it's a typo in the endpoint
        campaign: props.campaign,
      },
    })
    if (error) {
      error.value = error.message

      return false
    }

    if (result) {
      try {
        window.localStorage.setItem(localStorageKeys.hideNewsletter, true)
      } catch (e) {
        // ignore
      }

      success.value = true
    }
  } catch (error) {
    error.value = error.data?.message || t('serverError')
  }
})
</script>

<style lang="scss" scoped>
.newsletter-form {
  &-register-btn {
    border-radius: 18px !important;
  }
}
</style>
